<template>
  <div class="ma-5" style="position:relative;">
    <div class="inner-lottie">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
      <p
        :class="
          $vuetify.theme.dark
            ? 'subtitle-1 primary--text'
            : 'subtitle-1 black--text'
        "
      >
        Cargando Data
      </p>
    </div>
    <v-skeleton-loader height="94" type="card-heading"> </v-skeleton-loader>

    <v-skeleton-loader
      ref="skeleton"
      type="image"
      class="mx-auto d-none d-md-block"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

export default {
  name: "map-skeleton",
  mixins: [lottieSettings],
  components: {
    lottie: Lottie,
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
