<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <map-skeleton v-if="!business" />

    <v-overlay class="loading-center" :value="save">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-container fluid>
      <v-row class="pa-0 ma-0">
        <h1 v-if="business">
          Ubicación de
          <span class="font-weight-light">{{ business.shortName }}</span>
        </h1>
      </v-row>
    </v-container>

    <div class="main-container" v-if="business">
      <v-container fluid class="fill-height pr-5 screen-width">
        <v-row class="pt-3 ml-2">
          <v-col md="12" cols="12" sm="12">
            <div class="searchGeo">
              <i class="far fa-search"></i>
              <input
                type="text"
                v-model="searchTerm"
                v-on:keyup.enter="geoQuerySearch()"
                v-on:keyup.escape="searchResults = []"
                placeholder="Busca una dirección o ingresa las coordenadas"
              />
              <transition name="slide" appear>
                <div v-if="searchResults.length" class="matches">
                  <ul>
                    <li
                      v-for="result in searchResults"
                      :key="result.properties.osm_id"
                      @click="geoQuerySelect(result)"
                    >
                      <i class="fas fa-map-marker-alt"></i> {{ result.name }}
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <l-map
              @update:center="centerUpdated"
              class="fill-height"
              style="height: 60vh"
              :zoom="zoom"
              ref="myMap"
              :center="center"
            >
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker
                :lat-lng="[center[0], center[1]]"
                @update:lat-lng="positionUpdated"
                :draggable="editRoles.includes(user.type) ? true : false"
                :icon="getImage"
              >
                <l-popup v-if="$store.state.user.type == 'businessOwner'">
                  {{ business.zone }}!
                </l-popup>

                <l-tooltip v-else>
                  Mueva el marcador a la ubicación del comercio
                </l-tooltip>
              </l-marker>
              <l-geo-json
                :options-style="styleFunction"
                :options="options"
                :geojson="geojson"
              />

              <v-geosearch
                v-if="editRoles.includes(user.type)"
                :options="geosearchOptions"
              ></v-geosearch>
            </l-map>
          </v-col>
          <v-col v-if="editRoles.includes(user.type)" cols="12" md="6">
            <h2>Latitud</h2>
            <input
              type="number"
              class="control-input control-input-number"
              placeholder="Latitud"
              v-model="center[0]"
            />
          </v-col>

          <v-col v-if="editRoles.includes(user.type)" cols="12" md="6">
            <h2>Longitud</h2>
            <input
              type="number"
              class="control-input control-input-number"
              placeholder="Longitud"
              v-model="center[1]"
            />
          </v-col>

          <v-col cols="12">
            <h2>Dirección</h2>
            <textarea
              v-model="business.zone"
              placeholder="Dirección exacta del negocio"
              class="control-input"
              rows="3"
            ></textarea>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12">
            <!-- <v-divider></v-divider> -->
            <v-row justify="end">
              <v-btn
                class="save-btn"
                @click="updateBusinessInformation"
                color="primary"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import L from "leaflet";
import { latLng } from "leaflet";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";

import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
  LGeoJson,
  LControlZoom,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import mapSkeleton from "./skeleton/map-skeleton";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import ledgerMixin from "@/mixins/ledgerMixin";
require("leaflet-control-geocoder");

export default {
  name: "owner-map",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    mapSkeleton,
    LTooltip,
    VGeosearch,
    LGeoJson,
    LControlZoom,
  },
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      business: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      center: [47.41322, -1.219482],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      snackbar: false,
      snackbarText: "",
      save: false,
      prevValue: {},
      geojson: null,
      map: null,
      searchTerm: "",
      searchResults: [],
      editRoles: ["sudo", "AF", "EFCM", "AC", "admin"],
    };
  },
  watch: {
    selectedBusiness() {
      this.business = null;
      this.getBusinessData();
    },
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 5;
        }, 10);
      }
    },
  },
  computed: {
    ...mapState(["selectedBusiness", "user"]),
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    styleFunction() {
      const fillColor = this.fillColor;
      return () => {
        return {
          weight: 2,
          color: "#ff0000",
          opacity: 0.1,
          fillColor: fillColor,
          fillOpacity: 0.1,
        };
      };
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    getImage() {
      return L.icon({
        iconUrl:
          this.business.logo.original ||
          require("../../assets/user.svg"),
        shadowUrl: require("../../assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },
  methods: {
    positionUpdated(e) {
      if (this.editRoles.includes(this.user.type)) {
        this.center = [e.lat, e.lng];
      }
    },
    centerUpdated(e) {
      if (this.editRoles.includes(this.user.type)) {
        this.center = [e.lat, e.lng];
      }
    },
    handleSuccess() {
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Información actualizada correctamente.";
    },
    handleFail() {
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente";
    },
    async updateBusinessInformation() {
      this.save = true;

      var businessRef = db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"]);

      businessRef
        .update({
          zone: this.business.zone,
        })
        .then(async () => {
          if (this.editRoles.includes(this.user.type)) {
            if (this.center[0] && this.center[1]) {
              let data = {
                lat: this.center[0],
                lng: this.center[1],
                businessId: this.selectedBusiness[".key"],
              };

              var httpBusinessesUpdateGeoPoint = fb
                .functions()
                .httpsCallable("httpBusinessesUpdateGeoPoint");
              httpBusinessesUpdateGeoPoint(data)
                .then((result) => {
                  this.handleSuccess();
                })
                .catch(() => {
                  this.handleFail();
                });
            } else {
              this.save = false;
              this.snackbarText = "Coordenadas no válidas";
              this.snackbar = true;
            }
          } else {
            this.handleSuccess();
          }

          this.createLedger({
            source_id: this.selectedBusiness[".key"],
            source_type: "businesses",
            currentValue: this.selectedBusiness,
            prevValue: this.prevValue,
          });
        })
        .catch(() => {
          this.handleFail();
        });
    },
    getBusinessData() {
      return new Promise((resolve, reject) => {
        if (!this.selectedBusiness) {
          this.$router.push({ path: "/" });
        }

        db.collection("businesses")
          .doc(this.selectedBusiness[".key"])
          .onSnapshot(async (business) => {
            this.business = business.data();
            this.center = [
              this.business.geoAddress.geopoint.latitude,
              this.business.geoAddress.geopoint.longitude,
            ];

            return resolve(true);
          });

        this.zoom = 1;
      });
    },
    geoQuerySearch() {
      this.searchResults = [];
      if (this.searchTerm) {
        var re =
          /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/;
        let result = re.exec(this.searchTerm);
        if (result && result.length) {
          this.geoCoder.setQuery(result[0]);
          this.geoCoder._geocode();
        } else {
          this.geoCoder.setQuery(`Honduras,${this.searchTerm}`);
          this.geoCoder._geocode();
        }
      }
    },

    async geoQuerySelect(selection) {
      this.searchResults = [];
      this.searchTerm = "";
      this.center = [selection.center.lat, selection.center.lng];
    },
  },

  async mounted() {
    this.$store.state.visibleSearch = false;

    await this.$binding(
      "globals",
      db.collection(`settings`).doc("global")
    ).then(async (globals) => {
      this.geojson = JSON.parse(globals.coverageZone);

      this.geojson.features = this.geojson.features.filter(
        (e) => e.properties.color != "transparent"
      );

      await this.getBusinessData();
      this.prevValue = Object.assign({}, this.selectedBusiness);
      this.map = this.$refs.myMap.mapObject;

      this.geoCoder = L.Control.geocoder(
        {
          defaultMarkGeocode: false,
        }
        //   {
        //   geocoder: new L.Control.Geocoder.Google(
        //     "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho"
        //   ),
        //   defaultMarkGeocode: false,
        // }
      )
        .on("startgeocode", (e) => {
          // console.log(e);
          // this.$store.commit("setLoading", "Buscando coincidencias...");
        })
        .on("finishgeocode", (e) => {
          // console.log(e.results);
          // console.log(e);
          if (e.results.length > 0) {
            this.searchResults = e.results;
          }
          // this.$store.commit("setLoading", false);
        })
        .addTo(this.map);
    });
  },
};
</script>

<style lang="scss">
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}
.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}
@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}
.save-btn {
  margin-top: 20px;
}
.vue2leaflet-map {
  z-index: 1;
}

.searchGeo {
  appearance: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin: 0 5px;
  box-sizing: content-box;
  text-align: center;
  width: 500px;
  // z-index: 9999;
  border-radius: 10px;
  position: absolute;
  top: 15vh;
  // left: 50%;
  // margin-left: -250px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  transition: 0.4s 0s all cubic-bezier(0.44, 0.1, 0.28, 1.59);
  color: white;
  z-index: 2;
  left: calc(50% - 260px) !important;
  &::after {
    content: "Presiona ↵ para buscar";
    position: absolute;
    z-index: 2;
    color: #FF1744;
    text-align: right;
    font-size: 11px;
    line-height: 10px;
    width: 80px;
    top: 11px;
    right: 16px;
    opacity: 0;
    transition: 0.3s all;
  }
  &:hover::after {
    opacity: 1;
  }
  input {
    appearance: none;
    border: none;
    outline: none;
    padding: 5px 40px;
    padding-left: 40px;
    padding-right: 90px;
    background: none;
    font-size: 14px;
    font-weight: 500;
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    height: 42px;
    width: 100%;
    opacity: 0.9;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 20px rgba(#000000, 0.3));
    background-color: #fff;
    transition: 0.3s all;
    color: #666;
    &:focus,
    &:hover {
      color: #111;
      filter: drop-shadow(0px 0px 20px rgba(#000000, 0.4));
      opacity: 1;
    }
    &::placeholder {
      color: #333;
      opacity: 0.6;
    }
  }
  i {
    top: 10px;
    left: 10px;
    position: absolute;
    color: #FF1744;
    z-index: 2;
    font-size: 21px;
    margin-right: 10px;
  }
  .matches {
    width: 100%;
    box-sizing: border-box;
    max-height: 50vh;
    overflow: hidden;
    color: #111;
    text-align: left;
    font-weight: normal;
    margin: 10px 0;
    background-color: white;
    border-left: 5px solid white;
    border-right: 5px solid white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 20px rgba(#000000, 0.3));
    ul {
      list-style: none;
      max-height: 50vh;
      overflow: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 1px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #FF1744;
        border-radius: 10px;
        height: 10px;
      }
      li {
        padding: 5px 10px;
        padding-bottom: 10px;
        margin: 5px;
        font-size: 14px;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        cursor: pointer;
        i {
          color: #111;
          position: relative;
          top: 0;
          left: 0;
          margin-right: 5px;
          font-size: 16px;
          opacity: 0.2;
        }
        &:hover {
          color: #FF1744 !important;
          i {
            opacity: 1;
            color: #FF1744 !important;
          }
        }
        &:first-of-type {
          padding-top: 0px;
          margin-top: 20px;
        }
        &:last-of-type {
          padding-bottom: 0px;
          border-bottom: none;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.leaflet-control-geosearch .leaflet-bar-part {
  display: none !important;
}
</style>
